import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import { Link, useNavigate } from "react-router-dom";
import Container from "../components/Container";
import CustomInput from "../components/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordToken } from "../features/user/userSlice";
import "../components/login.css";

let emailSchema = yup.object({
  email: yup
    .string()
    .required("Email is Required")
    .email("Email Should be valid"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailSchema,
    onSubmit: (values) => {
      dispatch(forgotPasswordToken(values));
      // Optional: navigate to a confirmation page or alert user after successful dispatch
      // navigate('/confirmation');
    },
  });

  return (
    <>
    <div className="forgot-password1">
      <div className="signup-card">
        <h2 className="signup-title">Reset Your Password</h2>
        <p className="signup-subtitle">
        Enter your email to receive a password reset link.
        </p>
              <form
                className="d-flex flex-column gap-3"
                onSubmit={formik.handleSubmit}
              >
                {/* Email input field */}
                <CustomInput
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                  onBlur={formik.handleBlur("email")}
                />
                {/* Display validation error */}
                <div className="error text-danger">
                  {formik.touched.email && formik.errors.email}
                </div>

                {/* Submit and Cancel buttons */}
                <div className=" d-flex justify-content-center">
                  <button className="signup-button border-0 btn-primary px-4 py-2" type="submit">
                    Submit
                  </button>
                </div>
                <div className="text-center mt-3">
                  <Link to="/login" className="text-secondary">Cancel</Link>
                </div>
              </form>
            </div>
          </div>
          
        
    </>
  );
};

export default ForgotPassword;
