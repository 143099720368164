import React from "react";
import { Link, useLocation } from "react-router-dom";
import { BsLinkedin, BsGithub, BsYoutube, BsInstagram } from "react-icons/bs";
import newsletter from "../images/newsletter.png";

const Footer = () => {
  const location = useLocation(); // Get the current route

  // Check if the current path is '/login', and if it is, don't render the footer
  if (location.pathname === "/login") {
    return null; // Footer won't be rendered on the Login page
  }
  if(location.pathname=="/signup"){
    return null;
  }
  if(location.pathname=="/forgot-password"){
    return null;
  }

  return (
    <>
      <footer className="py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col">
              <h4 className="text-black mb-4">Contact Us</h4>
              <div>
                <address className="text-black fs-6">
                  Hno : Daiict college, Reliance Cross Rd, <br />{" "}
                  Gandhinagar, Bengaluru <br />
                  PinCode: 560078
                </address>
                <a
                  href="tel:+91 123456789"
                  className="mt-3 d-block mb-1 text-black"
                >
                  +91 123456789
                </a>
                <a
                  href="mailto:xyz@gmail.com"
                  className="mt-2 d-block mb-0 text-black"
                >
                  xyz@gmail.com
                </a>
                <div className="social_icons d-flex align-items-center gap-30 mt-4">
                  <a className="text-black" href="#">
                    <BsLinkedin className="fs-4" />
                  </a>
                  <a className="text-black" href="#">
                    <BsInstagram className="fs-4" />
                  </a>
                  <a className="text-black" href="#">
                    <BsGithub className="fs-4" />
                  </a>
                  <a className="text-black" href="#">
                    <BsYoutube className="fs-4" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-3">
              <h4 className="text-black mb-4">Information</h4>
              <div className="footer-link d-flex flex-column">
                <Link to="/privacy-policy" className="text-black py-2 mb-1">
                  Privacy Policy
                </Link>
                <Link to="/refund-policy" className="text-black py-2 mb-1">
                  Refund Policy
                </Link>
                <Link to="/wishlist" className="text-black py-2 mb-1">
                  Wishlist
                </Link>
                <Link to="/shipping-policy" className="text-black py-2 mb-1">
                  Shipping Policy
                </Link>
                <Link to="/term-conditions" className="text-black py-2 mb-1">
                  Terms & Conditions
                </Link>
                <Link className="text-black py-2 mb-1">Blogs</Link>
              </div>
            </div>
            <div className="col-3">
              <h4 className="text-black mb-4">Account</h4>
              <div className="footer-link d-flex flex-column">
                <Link to="/about" className="text-black py-2 mb-1">About Us</Link>
                <Link to="/contact" className="text-black py-2 mb-1">Faq</Link>
                <Link to="/contact" className="text-black py-2 mb-1">Contact</Link>
                
                <Link to="/my-profile" className="text-black py-2 mb-1">Profile</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="copyrights py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0 text-black">
                &copy; {new Date().getFullYear()}{" "}
                <a
                  href="https://www.node8.in"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "black" }}
                >
                  Node8 Innovations
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
