import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import Container from "../components/Container";
import backgroundImage from "../images/tasty-image.png";
import { db, auth } from "../firebase/FirebaseConfig";
import { collection, addDoc, getDoc, doc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const bowlTypes = [
  { id: "small", title: "Small Bowl", price: 2999 },
  { id: "medium", title: "Medium Bowl", price: 4999 },
  { id: "large", title: "Large Bowl", price: 1999 },
];

const sampleCartItems = [
  {
    _id: "1",
    productId: {
      title: "Small Bowl",
      type: "small",
      images: [{ url: "https://images.pexels.com/photos/1128678/pexels-photo-1128678.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" }],
    },
    price: 2999,
    quantity: 1,
    subscription: "1 week",
  },
  {
    _id: "2",
    productId: {
      title: "Medium Bowl",
      type: "medium",
      images: [{ url: "https://images.pexels.com/photos/1128678/pexels-photo-1128678.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" }],
    },
    price: 4999,
    quantity: 2,
    subscription: "3 months",
  },
  {
    _id: "3",
    productId: {
      title: "Large Bowl",
      type: "large",
      images: [{ url: "https://images.pexels.com/photos/1128678/pexels-photo-1128678.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" }],
    },
    price: 1999,
    quantity: 1,
    subscription: "6 months",
  },
];

const Cart = () => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [userCartState, setUserCartState] = useState(sampleCartItems);
  const [currentUser, setCurrentUser] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const sum = userCartState.reduce((acc, item) => acc + item.quantity * item.price, 0);
    setTotalAmount(sum);
  }, [userCartState]);

  useEffect(() => {
    if (currentUser) {
      const fetchUserDetails = async () => {
        try {
          setLoading(true);
          const userDoc = await getDoc(doc(db, "users", currentUser.uid));
          if (userDoc.exists()) {
            setUserDetails(userDoc.data());
          }
        } catch (error) {
          console.error("Error fetching user details: ", error);
        } finally {
          setLoading(false);
        }
      };
      fetchUserDetails();
    }
  }, [currentUser]);

  const updateProductQuantity = (cartItemId, quantity) => {
    if (quantity < 1 || quantity > 10) return;
    setUserCartState((prevCart) =>
      prevCart.map((item) =>
        item._id === cartItemId ? { ...item, quantity: Number(quantity) } : item
      )
    );
  };

  const deleteACartProduct = (id) => {
    setUserCartState((prevCart) => prevCart.filter((item) => item._id !== id));
  };

  const handleCheckout = async () => {
    if (!currentUser) {
      alert("Please log in to complete your order.");
      navigate("/login");
      return;
    }

    try {
      setLoading(true);
      const orderData = {
        user: {
          uid: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName,
          ...userDetails,
        },
        cartItems: userCartState,
        totalAmount: totalAmount,
        createdAt: new Date(),
      };

      // Create order document and get its ID
      const orderRef = await addDoc(collection(db, "Fruitsy", "orders", "orderId"), orderData);
      const orderId = orderRef.id;

      // Store the order ID in the "OrderId" subcollection
      await setDoc(doc(db, `orders/${orderId}/OrderId/${orderId}`), { orderId });

      alert("Order placed successfully!");
      setUserCartState([]);
      navigate("/orders", { state: { orderData } }); // Redirect to orders page with order data
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Failed to place order. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <section>
        <div
          className="banner1"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <h3 className="wishlist1-text">Cart</h3>
        </div>
      </section>
      <Container class1="cart-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="cart-header py-3 d-flex justify-content-between align-items-center">
              <h4 className="cart-col-1">Product</h4>
              <h4 className="cart-col-2">Price</h4>
              <h4 className="cart-col-3">Quantity</h4>
              <h4 className="cart-col-4">Total</h4>
            </div>
            {userCartState.map((item, index) => {
              return (
                <div
                  key={index}
                  className="cart-data py-3 mb-2 d-flex justify-content-between align-items-center"
                >
                  <div className="cart-col-1 gap-15 d-flex align-items-center">
                    <div className="w-25">
                      <img
                        src={item.productId.images[0].url}
                        className="img-fluid"
                        alt="product image"
                      />
                    </div>
                    <div className="w-75">
                      <p>{item.productId.title}</p>
                      <p>Subscription: {item.subscription}</p>
                    </div>
                  </div>
                  <div className="cart-col-2">
                    <h5 className="price">Rs. {item.price}</h5>
                  </div>
                  <div className="cart-col-3 d-flex align-items-center gap-15">
                    <div>
                      <input
                        className="form-control"
                        type="number"
                        min={1}
                        max={10}
                        value={item.quantity}
                        onChange={(e) =>
                          updateProductQuantity(item._id, e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <AiFillDelete
                        onClick={() => deleteACartProduct(item._id)}
                        className="text-danger "
                      />
                    </div>
                  </div>
                  <div className="cart-col-4">
                    <h5 className="price">Rs. {item.quantity * item.price}</h5>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-12 py-2 mt-4">
            <div className="d-flex justify-content-between align-items-baseline">
              <Link to="/" className="button">
                Continue To Shopping
              </Link>
              {totalAmount > 0 && (
                <div className="d-flex flex-column align-items-end">
                  <h4>SubTotal: Rs. {totalAmount}</h4>
                  <p>Taxes and shipping calculated at checkout</p>
                  <button onClick={handleCheckout} className="button">
                    Checkout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Cart;
