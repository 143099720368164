import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../components/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { auth, db } from "../firebase/FirebaseConfig.js"; // Import Firebase config
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import "../components/login.css";
import "../pages/Profile"
let signUpSchema = yup.object({
  firstname: yup.string().required("First Name is Required"),
  lastname: yup.string().required("Last Name is Required"),
  email: yup
    .string()
    .required("Email is Required")
    .email("Email should be valid"),
  mobile: yup
    .string()
    .required("Mobile No is Required")
    .matches(/^[0-9]{10}$/, "Mobile number is not valid"),
  password: yup.string().required("Password is Required"),
});

const Signup = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      password: "",
    },
    validationSchema: signUpSchema,
    onSubmit: async (values) => {
  try {
    // Try to create a new user
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      values.email,
      values.password
    );
    const user = userCredential.user;

    // Store additional user data in Firestore under a user-specific document
    await setDoc(doc(db,"Fruitsy", "users","userId",  user.uid,), {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      mobile: values.mobile,
      createdAt: serverTimestamp(),
    });

    alert("User registered successfully!");
    navigate("/login");
  } catch (error) {
    if (error.code === "auth/email-already-in-use") {
      alert("Email is already registered. Please log in.");
      navigate("/login");
    } else {
      console.error("Error:", error);
      alert(error.message);
    }
  }
},

  });

  return (
    <div className="signup-page">
      <div className="signup-card">
        <h2 className="signup-title">Create an Account</h2>
        <p className="signup-subtitle">
          Join us to access exclusive features and content.
        </p>
        <form onSubmit={formik.handleSubmit} className="signup-form">
          <CustomInput
            type="text"
            name="firstname"
            placeholder="First Name"
            value={formik.values.firstname}
            onChange={formik.handleChange("firstname")}
            onBlur={formik.handleBlur("firstname")}
          />
          <div className="error">
            {formik.touched.firstname && formik.errors.firstname}
          </div>
          <CustomInput
            type="text"
            name="lastname"
            placeholder="Last Name"
            value={formik.values.lastname}
            onChange={formik.handleChange("lastname")}
            onBlur={formik.handleBlur("lastname")}
          />
          <div className="error">
            {formik.touched.lastname && formik.errors.lastname}
          </div>
          <CustomInput
            type="email"
            name="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange("email")}
            onBlur={formik.handleBlur("email")}
          />
          <div className="error">
            {formik.touched.email && formik.errors.email}
          </div>
          <CustomInput
            type="tel"
            name="mobile"
            placeholder="Mobile Number"
            value={formik.values.mobile}
            onChange={formik.handleChange("mobile")}
            onBlur={formik.handleBlur("mobile")}
          />
          <div className="error">
            {formik.touched.mobile && formik.errors.mobile}
          </div>
          <CustomInput
            type="password"
            name="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange("password")}
            onBlur={formik.handleBlur("password")}
          />
          <div className="error">
            {formik.touched.password && formik.errors.password}
          </div>
          <button type="submit" className="signup-button">Sign Up</button>
          <div className="login-redirect">
            Already have an account? <Link to="/login">Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;