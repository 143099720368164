import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import wishlist from "../images/wishlist.svg";
import user from "../images/user.svg";
import cart from "../images/cart.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserCart } from "../features/user/userSlice";

const Header = () => {
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state?.auth?.cartProducts);
  const authState = useSelector((state) => state?.auth);
  const [total, setTotal] = useState(null);

  const getTokenFromLocalStorage = localStorage.getItem("customer")
    ? JSON.parse(localStorage.getItem("customer"))
    : null;

  const config2 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
      }`,
      Accept: "application/json",
    },
  };

  useEffect(() => {
    dispatch(getUserCart(config2));
  }, []);

  useEffect(() => {
    let sum = 0;
    for (let index = 0; index < cartState?.length; index++) {
      sum += Number(cartState[index].quantity) * cartState[index].price;
      setTotal(sum);
    }
  }, [cartState]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <header className="header-upper py-3">
      <div className="container-xxl">
        <div className="row align-items-center">
          {/* Logo */}
          <div className="col">
            <Link className="text-black" to="/">
              <h2>Fruitsy</h2>
            </Link>
          </div>
         

          {/* Menu Links */}
          <div className="col">
            <div className="menu-links d-flex align-items-center  justify-content-start gap-5">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/product">fruits</NavLink>
              <NavLink to="/my-orders">My Orders</NavLink>
              
              <NavLink to="/contact">Contact</NavLink>
            </div>
          </div>

          {/* Icons with Labels */}
          <div className="col d-flex align-items-center justify-content-end gap-30">
            {/* Wishlist 
            <Link to="/wishlist" className="d-flex flex-column align-items-center ">
              <img src={wishlist} alt="wishlist" />
              <span>Wishlist</span>
            </Link>*/}

            {/* User Account */}
            <Link to={authState?.user === null ? "/login" : "/my-profile"} className="d-flex flex-column align-items-center ">
              <img src={user} alt="user" />
              {authState?.user === null ? (
                <span>Login</span>
              ) : (
                <span>Welcome, {authState.user.firstname}</span>
              )}
            </Link>

            {/* Cart */}
            <Link to="/cart" className="d-flex flex-column align-items-center ">
              <img src={cart} alt="cart" />
              <span>Cart ({cartState?.length || 0})</span>
            </Link>

            {/* Logout Button */}
            
              <button
              className="d-flex flex-column align-items-center"                
              type="button"
                onClick={handleLogout}
              >
                <span>Logout</span>
              </button>
            
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
