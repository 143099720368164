import React, { useEffect, useState } from "react";
import Container from "../components/Container";
import { useLocation } from "react-router-dom";
import { db } from "../firebase/FirebaseConfig";
import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import backgroundImage from "../images/tasty-image.png";
import placeholderImage from "../images/best-fruits.webp";
import '../components/Pricing.css'; // Custom CSS

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [expandedOrder, setExpandedOrder] = useState(null); // Track expanded order
  const [loading, setLoading] = useState(true); // Loading state
  const location = useLocation();
  const currentUser = useSelector((state) => state.auth.user); // Assuming the user data is stored in Redux

  const fetchOrders = async (uid) => {
    try {
      console.log("Fetching orders for user ID:", uid); // Debug log
      const q = query(collection(db, "Fruitsy", "orders"), where("user.uid", "==", uid));
      const querySnapshot = await getDocs(q);
      const fetchedOrders = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const orderData = doc.data();
        const cartItems = await Promise.all(orderData.cartItems.map(async (item) => {
          const productDoc = await getDoc(doc(db, "Fruitsy", "products", item.productId));
          return {
            ...item,
            productDetails: productDoc.exists() ? productDoc.data() : {}
          };
        }));
        return { ...orderData, cartItems, id: doc.id };
      }));
      setOrders(fetchedOrders);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching orders: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state?.orderData) {
      // Order data passed from cart
      setOrders([location.state.orderData]);
      setLoading(false);
    } else if (currentUser?.uid) {
      fetchOrders(currentUser.uid);
    } else {
      setLoading(false); // Set loading to false if no user is logged in and no order data is passed
    }
  }, [location.state, currentUser]);

  const toggleOrderDetails = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <section>
        <div className="banner1" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <h3 className="wishlist1-text">Your Orders</h3>
        </div>
      </section>
      <Container class1="orders-wrapper home-wrapper-2 py-5">
        <div className="orders-list">
          {orders.length === 0 ? (
            <p>No orders found</p>
          ) : (
            orders.map((order, index) => (
              <div key={index} className="order-card">
                <div className="order-summary" onClick={() => toggleOrderDetails(order.id)}>
                  <div className="order-id">#{order.id}</div>
                  <div className="order-date">{new Date(order.createdAt.toDate()).toLocaleDateString()}</div>
                  <div className="order-total">Total: ${parseFloat(order.totalAmount).toFixed(2)}</div>
                  <div className={`order-status ${order.orderStatus?.toLowerCase()}`}>{order.orderStatus || "pending"}</div>
                </div>

                {expandedOrder === order.id && (
                  <div className="order-details">
                    <div className="order-product-list">
                      {order.cartItems.map((item, i) => (
                        <div key={i} className="order-product">
                          <img 
                            src={item.productDetails.images?.[0]?.url || placeholderImage} 
                            alt={item.productDetails.title || "Product"} 
                            className="product-image" 
                          />
                          <div className="product-details">
                            <p className="product-title">{item.productDetails.title || "Unknown Product"}</p>
                            <p>Price: ${item.price}</p>
                            <p>Quantity: {item.quantity}</p>
                            <p>Subscription: {item.subscription}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="order-info">
                      <h4>Order Information</h4>
                      <p><strong>Receiver:</strong> {order.user.displayName}</p>
                      <p><strong>Address:</strong> {order.user.address || "N/A"}</p>
                      <p><strong>Logistics Company:</strong> {order.logisticsCompany || "N/A"}</p>
                      <p><strong>Shipment Date:</strong> {order.shipmentDate || "N/A"}</p>
                      <div className="order-total-summary">
                        <p><strong>Subtotal:</strong> ${parseFloat(order.totalAmount).toFixed(2)}</p>
                        <p><strong>Shipping:</strong> $9.99</p>
                        <p className="order-total-amount"><strong>Total:</strong> ${(parseFloat(order.totalAmount) + 9.99).toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </Container>
    </>
  );
};

export default Orders;
