import React from "react";
import backgroundImage from "../images/tasty-image.png";
import '../components/Pricing.css';
/*css page is pricing.css*/
import Team from "../components/Team";

const About = () => {
  return (<>
  <section>
    <div className="banner1" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <h3 className="wishlist1-text">About Us</h3></div></section>
      <div className="our-company justify-center">
          <div className="w-full px-4">
            <div className="mx-auto mb-16 max-w-lg text-center pd-12">
              <span className="company mb-2 block text-lg font-semibold text-blue-600">
                Our Company
              </span>
              <h2 className="company1 mb-4 text-3xl font-bold leading-tight text-gray-800 dark:text-white sm:text-4xl md:text-5xl">
                Who We Are
              </h2>
              <p className="company2 text-base text-gray-600 dark:text-gray-400">
                There are many variations of passages of 
                Lorem Ipsum available,
                but the majority have suffered alteration in some form.There are many variations of passages of 
                Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </div>
      <section class="section1">
      <div class="container1">
        <article class="entity-side-image entity-image-left">
          <div class="entity-image"
          ><img class="w-100" src="../images/blog-1.jpg" alt=""/>
            </div>
          <div class="entity-content">
            <h2 class="entity-title">Who are we</h2>
            <p class="entity-subtext">What is our mission?</p>
            <p class="entity-text">Our mission is to bring our customers quality products, 
              at the lowest price with the best service in the industry. That is why we are
               premier online marijuana dispensary! We offer our clients a user-friendly platform 
               where you’re only a few clicks away from guaranteed delivery of the highest quality 
               marijuana products right to your front door-step.</p>
          </div></article><article class="entity-side-image">
          <div class="entity-image">
            <img class="w-100" src="../images/best-fruits.webp" alt=""/>
            </div>
          <div class="entity-content">
            <h2 class="entity-title">Why choose us</h2>
            <p class="entity-subtext">How are we the best?</p>
            <p class="entity-text">Customer satisfaction is our top priority, there is nothing more exciting to us than a repeat customer. With our price match policy along with guaranteed Xpresspost delivery, we have been regarded as one of most trusted online marijuana dispensaries. For more information please look at our F.A.Q. page for answers to common questions.</p></div>
        </article>
      </div>
    
    </section>
    <Team/>
    
    
    </>
      );
};

export default About;
