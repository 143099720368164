import React, { useEffect, useState } from "react";
import Container from "../components/Container";
import BreadCrumb from "../components/BreadCrumb";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../features/user/userSlice";
import { FiEdit } from "react-icons/fi";
import backgroundImage from "../images/tasty-image.png";
import '../components/Pricing.css';
import { auth, db } from "../firebase/FirebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";



// Profile form validation schema
const profileSchema = yup.object({
  firstname: yup.string().required("First Name is Required"),
  lastname: yup.string().required("Last Name is Required"),
  email: yup.string().required("Email is Required").email("Email Should be valid"),
  mobile: yup.number().required().positive().integer("Mobile No is Required"),
  address: yup.string().required("Address is required"),
  town: yup.string().required("Town/City is required"),
  postcode: yup.string().required("Postcode/Zip is required"),
  country: yup.string().required("Country is required"),
});

// Initial form values
const getInitialValues = (user) => ({
  firstname: user?.firstname || "",
  lastname: user?.lastname || "",
  email: user?.email || "",
  mobile: user?.mobile || "",
  address: user?.address || "",
  town: user?.town || "",
  postcode: user?.postcode || "",
  country: user?.country || "",
});

const Profile = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.auth.user);
  const [edit, setEdit] = useState(true);
  const [avatar, setAvatar] = useState(null);
  const [user, setUser] = useState(null);  // Ensure state is initialized correctly

  useEffect(() => {
    console.log("Profile component mounted"); 

    // Handle user authentication state changes
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Get user details from Firestore
        const userDoc = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userDoc);
        console.log("Check user in Private: ", user);
        

        if (userSnap.exists()) {
          console.log("User data fetched: ", userSnap.data()); 
          setUser(userSnap.data());  // Update state with user data
        } else {
          console.log("No user data found");
        }
      } else {
        console.log("No user is logged in");
      }
    } );
  }, []);

  const formik = useFormik({
    initialValues: getInitialValues(userState),
    enableReinitialize: true,
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      if (auth.currentUser) {
        const userDoc = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userDoc, {
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          mobile: values.mobile,
        });
        // Update user state with additional fields like address, postcode, etc.
        dispatch(updateProfile({ data: values }));
        setEdit(true);
      }
    },
  });

  const handleAvatarChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setAvatar(URL.createObjectURL(e.target.files[0]));
    }
  };
  
  // Render loading screen while fetching data
  if (!user) {
    return <div>Loading...</div>;  // Ensure loading state is handled
  }

  return (
    <>
      <section>
        <div className="banner1" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <h3 className="wishlist1-text">Profile</h3>
        </div>
      </section>
      <Container class1="profile-wrapper">
        <div className="profile-header d-flex justify-content-between align-items-center mb-4">
          <h3>Personal Information</h3>
          <FiEdit className="fs-3" onClick={() => setEdit(false)} />
        </div>

        <form onSubmit={formik.handleSubmit} className="profile-form">
          <div className="row">
            {/* Left Column */}
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="firstname" className="form-label">First Name *</label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  className="form-control"
                  disabled={edit}
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <div className="error">{formik.errors.firstname}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="lastname" className="form-label">Last Name *</label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  className="form-control"
                  disabled={edit}
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <div className="error">{formik.errors.lastname}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Your Email *</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  disabled={edit}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="error">{formik.errors.email}</div>
                )}
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="mobile" className="form-label">Mobile No *</label>
                <input
                  type="number"
                  name="mobile"
                  id="mobile"
                  className="form-control"
                  disabled={edit}
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <div className="error">{formik.errors.mobile}</div>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">Upload new avatar - JPEG 100x100</label>
                <div className="d-flex align-items-center">
                  <img
                    src={avatar || "https://via.placeholder.com/100"}
                    alt="Avatar"
                    className="avatar-preview me-3"
                  />
                  <input
                    type="file"
                    accept="image/jpeg"
                    className="form-control"
                    disabled={edit}
                    onChange={handleAvatarChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {!edit && (
            <div className="text-end">
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
          )}
        </form>
      </Container>

      {/* Billing Address Section */}
      <Container class1="profile-wrapper">
        <div className="profile-header d-flex justify-content-between align-items-center mb-4">
          <h3>Billing Address</h3>
          <FiEdit className="fs-3" onClick={() => setEdit(false)} />
        </div>

        <form onSubmit={formik.handleSubmit} className="profile-form">
          <div className="row">
            {/* Left Column */}
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="address" className="form-label">Address *</label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="form-control"
                  disabled={edit}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="error">{formik.errors.address}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="town" className="form-label">Town / City *</label>
                <input
                  type="text"
                  name="town"
                  id="town"
                  className="form-control"
                  disabled={edit}
                  value={formik.values.town}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.town && formik.errors.town && (
                  <div className="error">{formik.errors.town}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="postcode" className="form-label">Postcode *</label>
                <input
                  type="text"
                  name="postcode"
                  id="postcode"
                  className="form-control"
                  disabled={edit}
                  value={formik.values.postcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.postcode && formik.errors.postcode && (
                  <div className="error">{formik.errors.postcode}</div>
                )}
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="country" className="form-label">Country *</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  className="form-control"
                  disabled={edit}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.country && formik.errors.country && (
                  <div className="error">{formik.errors.country}</div>
                )}
              </div>
            </div>
          </div>

          {!edit && (
            <div className="text-end">
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
          )}
        </form>
      </Container>
    </>
  );
};

export default Profile;
