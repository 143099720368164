import React, { useState } from "react";
import { Container } from "react-bootstrap";
import backgroundImage from "../images/tasty-image.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure to import Bootstrap CSS

const WishlistPage = () => {
  const defaultProducts = [
    {
      _id: "1",
      title: "Apple",
      description: "Fresh and juicy apples",
      price: 100,
      quantity: 1,
      images: [{ url: "https://images.pexels.com/photos/1128678/pexels-photo-1128678.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" }]
    },
    {
      _id: "2",
      title: "Banana",
      description: "Sweet and ripe bananas",
      price: 50,
      quantity: 2,
      images: [{ url: "https://example.com/banana.jpg" }]
    },
    {
      _id: "3",
      title: "Orange",
      description: "Citrusy and vitamin-rich oranges",
      price: 80,
      quantity: 1,
      images: [{ url: "https://example.com/orange.jpg" }]
    }
  ];

  const [wishlistState, setWishlistState] = useState(defaultProducts);

  const removeFromWishlist = (id) => {
    setWishlistState((prevState) => prevState.filter(item => item._id !== id));
  };

  return (<>
    <section>
    <div className="banner1" style={{ backgroundImage: `url(${backgroundImage})` }}>
       <h3 className="wishlist1-text">Wishlist</h3></div></section>
    <Container className="wishlist-wrapper home-wrapper-2 py-5">
       

      <div className="wishlist-header">
        <div className="row">
          <div className="col-5">Product List</div>
          <div className="col-2">Price</div>
          <div className="col-2">Quantity</div>
          <div className="col-3">Total</div>
        </div>
      </div>

      <div className="wishlist-items">
        {wishlistState && wishlistState.length === 0 && (
          <div className="text-center fs-3">No Data</div>
        )}
        {wishlistState &&
          wishlistState.map((item, index) => (
            <div className="row wishlist-card align-items-center my-3" key={index}>
              <div className="col-1 text-center">
                <button
                  onClick={() => removeFromWishlist(item._id)}
                  className="remove-btn btn btn-danger"
                >
                  &times;
                </button>
              </div>
              <div className="col-4">
                <img
                  src={item.images[0]?.url || "images/default.jpg"}
                  alt={item.title}
                  className="img-fluid product-image"
                />
                <div>
                  <h5 className="product-title">{item.title}</h5>
                  <p className="product-description">
                    {item.description || "No description available"}
                  </p>
                </div>
              </div>
              <div className="col-2">
                <h6 className="price">Rs. {item.price}</h6>
              </div>
              <div className="col-2">
                <input
                  type="number"
                  min="1"
                  value={item.quantity || 1}
                  className="quantity-input form-control"
                  onChange={() => {}}
                />
              </div>
              <div className="col-3">
                <h6 className="total-price">
                  Rs. {(item.price * (item.quantity || 1)).toFixed(2)}
                </h6>
              </div>
            </div>
          ))}
      </div>
      
    </Container>
    </>
    
  );
};

export default WishlistPage;
