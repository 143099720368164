import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import BlogCard from "../components/BlogCard";
import ProductCard from "../components/ProductCard";
import SpecialProduct from "../components/SpecialProduct";
import Container from "../components/Container";
import PricingCard from "../components/PricingCard";
import { services } from "../utils/Data";
import prodcompare from "../images/prodcompare.svg";
import wish from "../images/wish.svg";
import wishlist from "../images/wishlist.svg";
import watch from "../images/watch.jpg";
import watch2 from "../images/watch-1.avif";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
import '../components/Pricing.css';
import backgroundImage from "../images/tasty-image.png";
import Testimonials from "../components/Testimonials";

import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import slide from "../images/slide-img.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../features/blogs/blogSlice";
import moment from "moment";
import { getAllProducts } from "../features/products/productSlilce";
import ReactStars from "react-rating-stars-component";
import { addToWishlist } from "../features/products/productSlilce";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import Slider from "react-slick";

// Sample dynamic pricing plans data
const pricingPlans = [
  {
    title: "Small Bowl",
    price: "$59",
    description: "Perfect for individual or small servings.",
    features: [
      "1 Bowl",
      "Fresh handpicked fruits",
      "Lifetime access",
      "Free updates",
      "Use on 1 project",
      "3 Months support",
    ],
    imgUrl:  "https://images.pexels.com/photos/1128678/pexels-photo-1128678.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", // Placeholder image
  },
  {
    title: "Medium Bowl",
    price: "$199",
    description: "Perfect for family and friends servings.",
    features: [
      "2 Bowls",
      "Fresh handpicked fruits",
      "Lifetime access",
      "Free updates",
      "Use on 3 projects",
      "4 Months support",
    ],
    imgUrl: "https://images.pexels.com/photos/1128678/pexels-photo-1128678.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", // Placeholder image
  },
  {
    title: "Large Bowl",
    price: "$256",
    description: "Perfect for large gatherings or events.",
    features: [
      "3 Bowls",
      "Fresh handpicked fruits",
      "Lifetime access",
      "Free updates",
      "Use on Unlimited projects",
      "12 Months support",
    ],
    imgUrl: "https://images.pexels.com/photos/1128678/pexels-photo-1128678.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", // Placeholder image
  },
];

const Home = () => {
  const blogState = useSelector((state) => state?.blog?.blog);
  const productState = useSelector((state) => state?.product?.product);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBlogs());
    dispatch(getAllProducts());
  }, [dispatch]);

  // Handle adding a bowl to the cart
  const addToCart = (plan) => {
    // You can replace this function to add the plan to the cart state
    console.log(`${plan.title} added to cart`);
  };

  return (
    <>
      <section className="slider_section position-relative">
        <Carousel interval={3000} ride="carousel" >
          <Carousel.Item>
            <div className="slider_item-box">
              <div className="slider_item-container">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="slider_item-detail">
                        <h1>Welcome to Our Fruits Shop</h1>
                        <p>
                          Fresh, handpicked fruits delivered to your door. Taste
                          the best nature has to offer.
                        </p>
                        <div className="d-flex">
                          <a href="#" className="text-uppercase custom_orange-btn me-3">
                            Shop Now
                          </a>
                          <a href="#" className="text-uppercase custom_dark-btn">
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="slider_img-box">
                        <img src="images/slide-img.png" alt="Fruit Plate" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
          <div className="slider_item-box">
            <div className="slider_item-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="slider_item-detail">
                      <h1>Fresh Fruits Delivered to You</h1>
                      <p>
                        Enjoy the freshness of handpicked fruits, delivered to
                        your doorstep every day. Taste the best from nature!
                      </p>
                      <div class="d-flex">
                      <a href="#" class="text-uppercase custom_orange-btn me-3">
                        Shop Now
                      </a>
                      <a href="#" class="text-uppercase custom_dark-btn">
                        Contact Us
                      </a>
                    </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slider_img-box">
                      <img src="images/slide-img.png" alt="Fruit Delivery" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        
        </Carousel>
      </section>

      <section className="relative z-10 overflow-hidden bg-white pb-12 pt-20 dark:bg-gray-900 lg:pb-24 lg:pt-32">

      <div className="container mx-auto px-4">
      <div className=" pricing-container1">
        
        <div className="flex flex-wrap justify-center">
          <div className="w-full px-4">
            <div className="mx-auto mb-16 max-w-lg text-center pd-12">
              <span className="mb-2 block text-lg font-semibold text-blue-600">
                
                Pricing Table
              </span>
              <h2 className="mb-4 text-3xl font-bold leading-tight text-gray-800 dark:text-white sm:text-4xl md:text-5xl">
                Our Pricing Plan
              </h2>
              <p className="text-base text-gray-600 dark:text-gray-400">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </div>
          {/* Grid layout for pricing cards */}
          <div className="pricing-container">
            {pricingPlans.map((plan, index) => (
              <div key={index} className="card">
                <h3 className="plan-title">{plan.title}</h3>
                <p className="price">
                  {plan.price} <span>/ year</span>
                </p>
                <p className="description">{plan.description}</p>
                <img src={plan.imgUrl} alt={plan.title} className="img-fluid" />
                <ul className="features">
                  {plan.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
                {/* Add to Cart button */}
                <button
                  className="choose-button"
                  onClick={() => addToCart(plan)}
                >
                  Add to Cart
                </button>
              </div>
            ))}
          </div>
        </div>
        </div>
      </section>

      <div className="banner1" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <h1 className="banner1-text">VERY TASTY FRUITS</h1>
      </div>

      <section>
        <Testimonials />
      </section>
    </>
  );
};

export default Home;
